import styled from "styled-components";
import { breakpoints } from "src/styles/styleUtils";

const CutWarningContainer = styled.div`
  position: absolute;
  background: #ffbe00;
  top: 13px;
  left: -13px;
  display: inline-block;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  transform: translateY(10px);

  p {
    margin: 0 !important;
    font-size: 12px;
    transform: translateY(1px);
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
    top: 15px;
    left: -15px;
  }
`;

export default function CutWarning({ isCut, isWithdrawn }) {
  return (
    <CutWarningContainer>
      {isCut && <p>Cut</p>}
      {isWithdrawn && <p>WD</p>}
    </CutWarningContainer>
  );
}
