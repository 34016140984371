import styled from "styled-components";
import { palette } from "../../styles/styleUtils";

interface PlayerImageInterface {
  headshotUrl: string;
  isSelected?: boolean;
}

const PlayerImageContainer = styled.div<{ height?: number; width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.neutral3};
  border-radius: 100px;
  height: 100%;
  width: 100%;
  border: 1px solid #af91574d;

  img {
    border-radius: 100px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .selected {
    opacity: 0.5;
  }
`;

export default function PlayerImage(props: PlayerImageInterface) {
  const { headshotUrl, isSelected } = props;

  return (
    <PlayerImageContainer className={isSelected && "selected"}>
      <img src={headshotUrl} />
    </PlayerImageContainer>
  );
}
