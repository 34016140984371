import styled from "styled-components";
import PlayerImage from "./PlayerImage";
import * as Popover from "@radix-ui/react-popover";
import CutWarning from "../CutWarning";
import { palette } from "../../styles/styleUtils";
import { FONTS, breakpoints } from "../../styles/styleUtils";
import { emptyHeadshot } from "../../utils/game";

interface PlayerCardInterface {
  name: string;
  isWithdrawn?: boolean;
  isCut?: boolean;
  isSelected?: boolean;
  children?: JSX.Element;
  started?: boolean;
  headshotUrl?: string;
}

const PlayerContainer = styled.div<{ isWithdrawn: boolean }>`
  padding: 20px;
  position: relative;
  min-width: 250px;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -webkit-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -moz-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    height: 215px;
    padding: 10px 5px;
    min-width: 50px;
  }

  h3 {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: ${FONTS.SERIF};
    margin: 0;
    min-height: 30px;
    font-size: 19px;
    color: ${palette.primary1};
    font-size: 21px;
    line-height: 20px;
    opacity: ${(props) => props.isWithdrawn && ".5"};

    @media (max-width: ${breakpoints.mobile}) {
      line-height: 15px;
      font-size: 16px;
    }
  }

  p {
    margin-top: 5px;
    display: flex;
    align-items: center;
    opacity: ${(props) => props.isWithdrawn && ".5"};
  }

  .selected {
    opacity: 0.5;
    cursor: initial;
  }
`;

export const PlayerImageContainer = styled.div<{ isWithdrawn: boolean }>`
  margin-bottom: 10px;
  margin-top: 5px;
  width: 120px;
  height: 120px;
  opacity: ${(props) => props.isWithdrawn && ".5"};

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 5px;
    width: 75px;
    height: 75px;
  }
`;

export default function PlayerCard(props: PlayerCardInterface) {
  const { name, isSelected, children, isWithdrawn, started, isCut, headshotUrl } = props;

  return (
    <PlayerContainer isWithdrawn={isWithdrawn || isCut || started}>
      {!isWithdrawn && !isCut ? <PlayerCardOptionsTooltip /> : null}
      {isWithdrawn || isCut ? <CutWarning isWithdrawn={isWithdrawn} isCut={isCut} /> : null}
      <PlayerImageContainer isWithdrawn={isWithdrawn || isCut || started}>
        <PlayerImage isSelected={isSelected} headshotUrl={headshotUrl || emptyHeadshot()} />
      </PlayerImageContainer>
      <h3 className={isSelected && "selected"}>{name}</h3>
      {children}
    </PlayerContainer>
  );
}

const StyledPopoverTrigger = styled(Popover.Trigger)`
  position: absolute;
  transition: 0.2s;
  right: 7px;
  top: 7px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(212, 217, 226);
  cursor: pointer;
  font-weight: 900;
  border-radius: 50px;
  color: ${palette.neutral1};
  &:hover {
    transform: translateY(-2px);
  }
`;

const DropdownContent = styled(Popover.Content)`
  background: ${palette.neutral1};
  box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -webkit-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -moz-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  padding: 25px;
  border-radius: 4px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  will-change: transform, opacity;
  transform: translate(-20px, 5px);
  max-width: 350px;

  @media (max-width: ${breakpoints.mobile}) {
    width: 90vw;
    margin: 0 27px;

    box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.5);
    -webkit-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.5);
    -moz-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.5);
  }

  p:first-child {
    margin-bottom: 15px;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &[data-state="open"] {
    &[data-side="bottom"] {
      animation-name: fade;
    }
  }
`;

const StyledDropdownArrow = styled(Popover.Arrow)`
  width: 20px;
  height: 15px;
  fill: ${palette.neutral1};
  transform: translateX(-20px);
`;

const DropdownItem = styled.div`
  margin-bottom: 15px;

  .option-title {
    font-weight: 700;
  }

  .option-description {
  }

  &.notice {
    font-style: italic;
    color: ${palette.neutral4};
  }
`;

function PlayerCardOptionsTooltip() {
  return (
    <Popover.Root>
      <StyledPopoverTrigger>?</StyledPopoverTrigger>
      <Popover.Portal>
        <DropdownContent align="start">
          <StyledDropdownArrow />
          <DropdownItem>
            <p className="option-description">
              <span className="option-title">Choose</span> - Add golfer to your roster.
            </p>
          </DropdownItem>
          <DropdownItem>
            <p className="option-description">
              <span className="option-title">Drop</span> - Remove golfer as your selection.
            </p>
          </DropdownItem>
          <DropdownItem>
            <p className="option-description">
              <span className="option-title">Replace</span> - Replace current selection with this golfer.
            </p>
          </DropdownItem>
          <DropdownItem className="notice">You can use each golfer in one category only.</DropdownItem>
        </DropdownContent>
      </Popover.Portal>
    </Popover.Root>
  );
}
