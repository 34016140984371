import Search from "../Icons/Search";
import styled from "styled-components";
import { breakpoints } from "../../styles/styleUtils";

const SearchContainer = styled.div`
  position: relative;
  width: 230px;

  i {
    position: absolute;
  }

  .input-field {
    &::placeholder {
      opacity: 0.5;
    }
    &:-ms-input-placeholder {
      opacity: 0.5;
    }
    &::-ms-input-placeholder {
      opacity: 0.5;
    }
    box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
    -webkit-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
    -moz-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
    height: 46px;
    border-radius: 4px;
    border: 0px;
    padding-left: 15px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const PicksModalSearch = styled.input`
  width: 100%;
`;

const SearchIcon = styled(Search)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

interface SearchInputProps {
  placeHolder: string;
  onChange: (string) => void;
}

const SearchInput = ({ placeHolder, onChange }: SearchInputProps) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <SearchContainer className="search">
      <PicksModalSearch className="input-field" placeholder={placeHolder} onChange={(e) => handleChange(e)} />
      <SearchIcon />
    </SearchContainer>
  );
};

export default SearchInput;
