import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import { breakpoints } from "src/styles/styleUtils";

const CountdownCTA = styled.p`
  color: rgb(21, 21, 21);
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    color: rgb(21, 21, 21);
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

const CountdownClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
`;

const ClockContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  height: 70px;
  width: 70px;
  margin: 5px;
  box-shadow: 0 4px 36px rgba(0, 10, 22, 0.15);
  -webkit-box-shadow: 0 4px 36px rgba(0, 10, 22, 0.15);
  -moz-box-shadow: 0 4px 36px rgba(0, 10, 22, 0.15);

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 100%;
    width: 100%;
    transform: translateY(-5px);
    color: rgb(21, 21, 21);
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 16.7px;
    text-align: center;
  }

  p {
    transform: translateY(-14px);
    margin: 0;
    font-size: 11px;
    letter-spacing: 0px;
    line-height: 7.59px;
    text-align: center;
    text-transform: uppercase;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    height: 110px;
    width: 120px;
    margin: 25px;

    h2 {
      margin: 0 0 4px 0;
      color: rgb(21, 21, 21);
      font-size: 52px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 27.16px;
      text-align: center;
    }

    p {
      margin: 0;
      font-size: 15px;
      height: 12px;
      letter-spacing: 0px;
      line-height: 12.34px;
      text-align: center;
      text-transform: uppercase;
    }
  }
`;

const TimerMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1.33px;
  margin-top: 50px;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 18px;
  }
`;

interface CountdownProps {
  countDownTo: string;
}

const Countdown: FunctionComponent<CountdownProps> = ({ countDownTo }) => {
  const [countDownEnd, setCountDownEnd] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);

  const calculateTimeLeft = () => {
    const difference = countDownEnd - +new Date().getTime();
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
  };
  useEffect(() => {
    if (countDownTo) {
      setCountDownEnd(new Date(countDownTo).getTime());
    }
  }, [countDownTo]);

  useEffect(() => {
    if (countDownEnd) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDownEnd, timeLeft]);

  return (
    <CountdownClockContainer>
      {countDownTo ? (
        <>
          <CountdownCTA>Make your picks before time runs out!</CountdownCTA>
          <ClockContainer>
            <TimeContainer>
              <h2>{timeLeft?.days || 0}</h2>
              <p>DAYS</p>
            </TimeContainer>
            <TimeContainer>
              <h2>{timeLeft?.hours || 0}</h2>
              <p>HOURS</p>
            </TimeContainer>
            <TimeContainer>
              <h2>{timeLeft?.minutes || 0}</h2>
              <p>MINUTES</p>
            </TimeContainer>
            <TimeContainer>
              <h2>{timeLeft?.seconds || 0}</h2>
              <p>SECONDS</p>
            </TimeContainer>
          </ClockContainer>
        </>
      ) : (
        <TimerMessageContainer>
          New Roster creation will lock when the first of our five categories is out of pickable options. However, users
          who have created a roster are free to swap out any player on their team that hasn’t teed off yet up until the
          last golfer tees off in Round 1.
        </TimerMessageContainer>
      )}
    </CountdownClockContainer>
  );
};

export default Countdown;
